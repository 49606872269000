import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropDown from './DropDown';
import TableLayout from './TableLayout';
import Alert from 'react-bootstrap/Alert';
import { fetchTrackingSettings } from '../utils/API';

export interface Component {
  name: string;
  events: [];
}

interface Components {
  component: Component[]
}

const Body: React.FC = () => {
  const defaultSelectedComponent:Component = { name: "", events: []};
  const [components, setComponents] = useState<Components['component']>([]);
  const [selectedComponent, setSelectedComponent] = useState<Component>(defaultSelectedComponent);
  const [error, setError] = useState<String>("");

  useEffect(() => {
    fetchTrackingSettings().then(res => {
      if(!res){
        throw new Error("Unable to fetch components.");
      }
      if (res.defaultComponents.length === 0) {
        throw new Error("No results found.");
      }
        const  { defaultComponents } = res;
        setComponents(defaultComponents);
      }
    ).catch(err => {
      setError(err.toString());
    });
  },[]);

  const selectComponent = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const name = event.target.value;
    const component = components.find( (c:{ name: string}) => c.name === name) || defaultSelectedComponent;
    setSelectedComponent(component);
  }
  return (
    <Container id="main">
      <Alert variant="danger" style={{ opacity: error ? 1 : 0, marginBottom: 10 }}>
          {error}
      </Alert>
      <Row>
        <Col lg={{ span: 6, offset: 3 }}>
          <DropDown components={components} selectComponent={selectComponent}></DropDown>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <TableLayout events={selectedComponent.events}></TableLayout>
        </Col>
      </Row>
    </Container>
  );
}

export default Body;

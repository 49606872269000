import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Component } from './Body';

interface DropDownProps {
     components: Component[];
     selectComponent: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const DropDown: React.FC<DropDownProps> = ({components, selectComponent}) => {

     const renderComponentList = (): JSX.Element[] => {
          return components.map(component => {
               return (
                    <option>{component.name}</option>
               );
          });
     };

     return (
          <Form id="componentSelectForm">
               <FloatingLabel controlId="floatingSelect" label="Component">
                    <Form.Select onChange={selectComponent}>
                         <option>Select a component:</option>
                         {renderComponentList()}
                    </Form.Select>
               </FloatingLabel>
          </Form>
     )
}

export default DropDown;
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

const Header: React.FC = () => {
  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="#">DA Tracking</Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link href="#">Event Names</Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default Header;

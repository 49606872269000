import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

const Footer: React.FC = () => {
  return (
    <Navbar bg="dark" variant="dark" fixed="bottom">
    <Container>
      <Navbar.Brand href="https://docs.google.com/spreadsheets/d/12BLebbT4JWe7hRoP31XFF7LuQ28dBaeQtX3phfy0EDA/">airModules Event Tracking sheet</Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          Email us: <a href="mailto:data.analytics@everymundo.com">data.analytics@everymundo.com</a>
        </Navbar.Text>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  );
}

export default Footer;

import Table from 'react-bootstrap/Table';

interface TableProps {
     events: { 
          name: string;
          eventCategory: string;
          eventAction: string;
          gaLabelTemplate: {
               labelTemplate: string
          };
     }[];
}

const TableLayout: React.FC<TableProps> = ({events}) => {

     const renderTable = (): JSX.Element[] => {
          return events.map(event => {
               return (
                    <tr>
                         <td>{event.name}</td>
                         <td>{event.eventCategory}</td>
                         <td>{event.eventAction}</td>
                         <td>{isolateActionLabel(event.gaLabelTemplate.labelTemplate)}</td>
                    </tr>
               )
          });
     };

     const isolateActionLabel = (template:string):string => {
          const templateObj = JSON.parse(template);
          const label =  templateObj.l;
          return label.replace(/{{|}}/g, '')
     }

     return (
          <>
               { events && events.length > 0 ? 
                    <Table striped bordered size="sm" hover id="eventTable">
                         <thead>
                              <tr>
                                   <th>Pubsub Event Name</th>
                                   <th>Event Category</th>
                                   <th>Event Action</th>
                                   <th>Action label</th>
                              </tr>
                         </thead>
                         <tbody>
                              {renderTable()}
                         </tbody>
                    </Table>
                    : null
               }
          </>
     )
}

export default TableLayout;